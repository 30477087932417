import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header sticky-top d-flex align-items-center justify-content-between p-2 bg-light mb-5" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-column align-items-center justify-content-end flex-grow-1"
}
const _hoisted_3 = { class: "row w-100" }
const _hoisted_4 = { class: "user-email col text-end text-muted small" }
const _hoisted_5 = { class: "row w-100" }
const _hoisted_6 = { class: "col text-end header-buttons" }
const _hoisted_7 = { class: "mb-2" }

import { onMounted, ref, provide } from 'vue'
import { useRouter } from 'vue-router';

import CydAPIClient from './cyd-api-client';
import { UserPremiumAPIResponse } from './cyd-api-client';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const loggedIn = ref(false);
const router = useRouter();

const userEmail = ref(localStorage.getItem('userEmail') || '');

// Initialize the API client
let apiURL;
if (window.location.hostname == 'dash.cyd.social') {
  apiURL = "https://api.cyd.social";
} else if (window.location.hostname == 'dev-dash.cyd.social') {
  apiURL = "https://dev-api.cyd.social";
} else {
  apiURL = "http://127.0.0.1:5000";
}
console.log(`hostname=${window.location.hostname}`);
console.log(`apiURL=${apiURL}`);

const getAPIClient = () => {
  const apiClient = new CydAPIClient();
  apiClient.initialize(apiURL);
  apiClient.setUserEmail(localStorage.getItem('userEmail') || '');
  apiClient.setDeviceToken(localStorage.getItem('deviceToken') || '');
  userEmail.value = localStorage.getItem('userEmail') || '';
  return apiClient;
}
provide('getAPIClient', getAPIClient);

const checkIfSignedIn = async (): Promise<boolean> => {
  const apiClient = getAPIClient();
  const pingResp = await apiClient.ping();

  if (pingResp) {
    console.log('Logged in.');
    loggedIn.value = true;
    return true;
  }

  console.log('Not logged in.');
  loggedIn.value = false;
  localStorage.removeItem('userEmail');
  localStorage.removeItem('deviceToken');
  localStorage.removeItem('deviceUUID');
  return false;
}
provide('checkIfSignedIn', checkIfSignedIn);

const signInRequired = async (): Promise<void> => {
  if (await checkIfSignedIn()) {
    return;
  }

  // Save the current route so we can redirect back to it after sign-in
  localStorage.setItem('redirectRoute', router.currentRoute.value.fullPath);
  document.location.href = '/';
}
provide('signInRequired', signInRequired);

// User's Premium status
const userPremium = ref<UserPremiumAPIResponse | null>(null);
const fetchUserPremium = async () => {
  const apiClient = getAPIClient();
  const resp = await apiClient.getUserPremium();
  if (resp && 'error' in resp === false) {
    userPremium.value = resp;
    console.log('User premium status', JSON.parse(JSON.stringify(resp)));
  } else {
    console.error('Failed to fetch user premium status.');
  }
};
provide('userPremium', userPremium);
provide('fetchUserPremium', fetchUserPremium);

const signOutClicked = async () => {
  const apiClient = getAPIClient();

  // Delete the logged in device
  const deleteDeviceResp = await apiClient.deleteDevice({
    uuid: localStorage.getItem('deviceToken') || ''
  });
  if (deleteDeviceResp !== undefined && deleteDeviceResp.error) {
    console.error(`Error deleting device: ${deleteDeviceResp.message}`);
  }

  localStorage.removeItem('userEmail');
  localStorage.removeItem('deviceToken');
  loggedIn.value = false;
  router.push('/');
}

onMounted(async () => {
  if (await checkIfSignedIn()) {
    await fetchUserPremium();
    if (router.currentRoute.value.path == "/") {
      router.push('/dashboard');
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-flex align-items-center justify-content-start flex-grow-1 header-text" }, [
        _createElementVNode("a", { href: "https://cyd.social" }, [
          _createElementVNode("img", {
            src: "/icon.svg",
            alt: "Cyd",
            class: "cyd-icon me-4"
          })
        ]),
        _createTextVNode(" manage your "),
        _createElementVNode("img", {
          src: "/wordmark.svg",
          alt: "Cyd",
          class: "wordmark ms-2 me-2"
        }),
        _createTextVNode(" account ")
      ], -1)),
      (loggedIn.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createTextVNode(_toDisplayString(userEmail.value) + " ", 1),
                _createElementVNode("a", {
                  href: "#",
                  onClick: signOutClicked,
                  class: "btn btn-sm btn-outline-secondary"
                }, "Sign out")
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/dashboard",
                  class: "btn btn-link",
                  "active-class": "active"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Dashboard")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: "/teams",
                  class: "btn btn-link",
                  "active-class": "active"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Cyd for Teams")
                  ])),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_view)
    ])
  ]))
}
}

})