import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/dashboard/buy',
    name: 'dashboard-buy',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('../views/TeamsView.vue')
  },
  {
    path: '/teams/:uuid',
    name: 'team',
    component: () => import('../views/TeamsView.vue')
  },
  {
    path: '/native-login/:userEmail/:deviceToken/:mode',
    name: 'native-login',
    component: () => import('../views/NativeLoginView.vue')
  },
  {
    path: '/wait-for-payment',
    name: 'wait-for-payment',
    component: () => import('../views/WaitForPaymentView.vue')
  },
  {
    path: '/wait-for-payment/:uuid',
    name: 'teams-wait-for-payment',
    component: () => import('../views/TeamsWaitForPaymentView.vue')
  },
  {
    path: '/invitations/:token/accept',
    name: 'accept-invitation',
    component: () => import('../views/AcceptSeatInvitation.vue')
  },
  {
    path: '/admin-invitations/:token/accept',
    name: 'accept-admin-invitation',
    component: () => import('../views/AcceptAdminInvitation.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
