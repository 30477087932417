<script setup lang="ts">
import { onMounted, ref, watch, inject } from 'vue';
import { useRouter } from 'vue-router'

import CydAPIClient from '@/cyd-api-client';

import validator from 'validator';
import UAParser from 'ua-parser-js';

const router = useRouter();

const fetchUserPremium = inject('fetchUserPremium') as () => Promise<void>;

const getAPIClient = inject('getAPIClient') as () => CydAPIClient;
const apiClient = getAPIClient();

const checkIfSignedIn = inject('checkIfSignedIn') as () => Promise<boolean>;

type SignInState = 'start' | 'registerDevice' | 'token';
const signInState = ref<SignInState>('start');

const email = ref<string>("");
const continueButtonEnabled = ref(false);
const verificationCode = ref<string>("");
const verificationCodeDisabled = ref(false);
const verificationCodeInputEl = ref<HTMLInputElement | null>(null);

const parser = new UAParser();
const ua = parser.getResult();
const deviceDescription = `${ua.os.name}: ${ua.browser.name} Browser`;

watch(email, (newEmail) => {
  if (newEmail && typeof newEmail === 'string' && validator.isEmail(newEmail)) {
    continueButtonEnabled.value = newEmail.trim() !== '';
  }
});

const authenticate = async () => {
  if (!email.value || !validator.isEmail(email.value)) {
    return;
  }

  const resp = await apiClient.authenticate({
    email: email.value
  });
  if (typeof resp !== 'boolean' && resp.error) {
    if (resp.status == 403) {
      alert('At the moment, sign-ins are restricted to specific people. Sorry!');
      email.value = '';
      return;
    }

    alert('Failed to sign in. Check your email address and try again.');
    return;
  }

  apiClient.setUserEmail(email.value);

  signInState.value = 'registerDevice';
};

const verificationCodeFixInput = (event: Event) => {
  const inputEvent = event as InputEvent;
  const target = inputEvent.target as HTMLInputElement;
  verificationCode.value = target.value.replace(/\D/g, '').slice(0, 6);
  if (verificationCode.value.length >= 6) {
    verificationCodeDisabled.value = true;
    setTimeout(registerDevice, 100);
  }
};

const registerDevice = async () => {
  // Register the device
  const registerDeviceResp = await apiClient.registerDevice({
    email: email.value,
    verification_code: verificationCode.value,
    description: deviceDescription,
    device_type: 'browser'
  });
  if ("error" in registerDeviceResp) {
    verificationCode.value = '';
    verificationCodeInputEl.value?.focus();
    alert('Invalid verification code.');
    verificationCodeDisabled.value = false;
    return;
  }
  if (!registerDeviceResp.device_token) {
    alert('Failed to register device. Please try again later.');
    await goBack();
    return;
  }

  // Save to localStorage
  localStorage.setItem('userEmail', email.value);
  localStorage.setItem('deviceUUID', registerDeviceResp.uuid);
  localStorage.setItem('deviceToken', registerDeviceResp.device_token);

  // Get a new API token
  apiClient.setDeviceToken(registerDeviceResp.device_token);
  const pingResp = await apiClient.ping();
  if (!pingResp) {
    alert('Failed to register new device. Please try again later.');
    await goBack();
    return;
  }

  // If login was successful, this should redirect to the dashboard
  if (await checkIfSignedIn()) {
    await fetchUserPremium();

    // Redirect
    let redirectRoute = localStorage.getItem('redirectRoute');
    if (redirectRoute) {
      localStorage.removeItem('redirectRoute');
      router.push(redirectRoute);
    } else {
      router.push('/dashboard');
    }
  }
};

async function goBack() {
  verificationCode.value = '';
  signInState.value = 'start';
}

onMounted(async () => {
  email.value = localStorage.getItem('userEmail') || '';
  if (!await checkIfSignedIn()) {
    router.push('/');
  }
});
</script>

<template>
  <div class="container home-wrapper">
    <div class="teaser">
      <p class="fw-bold">It's time to regain control of your data.</p>
    </div>

    <hr />

    <template v-if="signInState == 'start'">
      <p class="text-center">Enter your email to sign in or create a Cyd account.</p>
      <form @submit.prevent="authenticate" class="sign-in-form d-flex">
        <input type="email" class="form-control me-2" placeholder="Email address" v-model="email" required>
        <button type="submit" class="btn btn-primary" :disabled="!continueButtonEnabled">Continue</button>
      </form>
    </template>
    <template v-else-if="signInState == 'registerDevice'">
      <div class="sign-in-form">
        <p class="text-center">We've emailed you a verification code. Enter it below.</p>
        <input rel="verificationCodeInputEl" type="text" class="form-control me-2" placeholder="123456"
          v-model="verificationCode" :disabled="verificationCodeDisabled" @input="verificationCodeFixInput" required>
        <div class="text-right mt-2">
          <button class="btn btn-sm btn-secondary" @click="goBack">Back</button>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.home-wrapper {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.teaser {
  text-align: center;
  font-size: 2em;
  max-width: 650px;
  margin: 0 auto;
}

.sign-in-form {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  margin: 0 auto;
}

.sign-in-form input {
  font-size: 1.25em;
  flex: 1;
}

hr {
  margin: 50px 0;
  color: #666;
}

.text-right {
  text-align: right;
}
</style>