<script setup lang="ts">
import { onMounted, ref, provide } from 'vue'
import { useRouter } from 'vue-router';

import CydAPIClient from './cyd-api-client';
import { UserPremiumAPIResponse } from './cyd-api-client';

const loggedIn = ref(false);
const router = useRouter();

const userEmail = ref(localStorage.getItem('userEmail') || '');

// Initialize the API client
let apiURL;
if (window.location.hostname == 'dash.cyd.social') {
  apiURL = "https://api.cyd.social";
} else if (window.location.hostname == 'dev-dash.cyd.social') {
  apiURL = "https://dev-api.cyd.social";
} else {
  apiURL = "http://127.0.0.1:5000";
}
console.log(`hostname=${window.location.hostname}`);
console.log(`apiURL=${apiURL}`);

const getAPIClient = () => {
  const apiClient = new CydAPIClient();
  apiClient.initialize(apiURL);
  apiClient.setUserEmail(localStorage.getItem('userEmail') || '');
  apiClient.setDeviceToken(localStorage.getItem('deviceToken') || '');
  userEmail.value = localStorage.getItem('userEmail') || '';
  return apiClient;
}
provide('getAPIClient', getAPIClient);

const checkIfSignedIn = async (): Promise<boolean> => {
  const apiClient = getAPIClient();
  const pingResp = await apiClient.ping();

  if (pingResp) {
    console.log('Logged in.');
    loggedIn.value = true;
    return true;
  }

  console.log('Not logged in.');
  loggedIn.value = false;
  localStorage.removeItem('userEmail');
  localStorage.removeItem('deviceToken');
  localStorage.removeItem('deviceUUID');
  return false;
}
provide('checkIfSignedIn', checkIfSignedIn);

const signInRequired = async (): Promise<void> => {
  if (await checkIfSignedIn()) {
    return;
  }

  // Save the current route so we can redirect back to it after sign-in
  localStorage.setItem('redirectRoute', router.currentRoute.value.fullPath);
  document.location.href = '/';
}
provide('signInRequired', signInRequired);

// User's Premium status
const userPremium = ref<UserPremiumAPIResponse | null>(null);
const fetchUserPremium = async () => {
  const apiClient = getAPIClient();
  const resp = await apiClient.getUserPremium();
  if (resp && 'error' in resp === false) {
    userPremium.value = resp;
    console.log('User premium status', JSON.parse(JSON.stringify(resp)));
  } else {
    console.error('Failed to fetch user premium status.');
  }
};
provide('userPremium', userPremium);
provide('fetchUserPremium', fetchUserPremium);

const signOutClicked = async () => {
  const apiClient = getAPIClient();

  // Delete the logged in device
  const deleteDeviceResp = await apiClient.deleteDevice({
    uuid: localStorage.getItem('deviceToken') || ''
  });
  if (deleteDeviceResp !== undefined && deleteDeviceResp.error) {
    console.error(`Error deleting device: ${deleteDeviceResp.message}`);
  }

  localStorage.removeItem('userEmail');
  localStorage.removeItem('deviceToken');
  loggedIn.value = false;
  router.push('/');
}

onMounted(async () => {
  if (await checkIfSignedIn()) {
    await fetchUserPremium();
    if (router.currentRoute.value.path == "/") {
      router.push('/dashboard');
    }
  }
})
</script>

<template>
  <div>
    <div class="header sticky-top d-flex align-items-center justify-content-between p-2 bg-light mb-5">
      <div class="d-flex align-items-center justify-content-start flex-grow-1 header-text">
        <a href="https://cyd.social"><img src="/icon.svg" alt="Cyd" class="cyd-icon me-4"></a>
        manage your
        <img src="/wordmark.svg" alt="Cyd" class="wordmark ms-2 me-2">
        account
      </div>
      <div v-if="loggedIn" class="d-flex flex-column align-items-center justify-content-end flex-grow-1">
        <div class="row w-100">
          <div class="user-email col text-end text-muted small">
            {{ userEmail }}
            <a href="#" @click="signOutClicked" class="btn btn-sm btn-outline-secondary">Sign out</a>
          </div>
        </div>
        <div class="row w-100">
          <div class="col text-end header-buttons">
            <router-link to="/dashboard" class="btn btn-link" active-class="active">Dashboard</router-link>
            <router-link to="/teams" class="btn btn-link" active-class="active">Cyd for Teams</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <router-view />
    </div>
  </div>
</template>

<style>
.header {
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.header .cyd-icon {
  width: 70px;
}

.header .wordmark {
  height: 50px;
}

.header .header-buttons a {
  text-decoration: none;
  font-size: 1rem;
  padding: 0 0.5rem;
}

.user-email {
  margin-right: 0.5rem;
}

.user-email a {
  font-size: 0.8em;
  padding: 0.2em 0.5em;
  margin: 0 0 0 0.5em;
}

.active {
  font-weight: bold;
  color: #007bff;
}

p.feature-header {
  text-align: center;
  margin-bottom: 0.25em;
  padding-bottom: 0.25em;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

ul.features {
  list-style-type: none;
  padding: 0;
}

ul.features li {
  padding: 0.5em 0;
}

ul.features li .check {
  color: green;
}

ul.features li .xmark {
  color: red;
}

.pricing-column {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
  flex: 1;
  margin: 10px;
  padding: 3rem 2rem;
}

.features .fa-circle-check,
.features .fa-circle-xmark {
  margin-top: 5px;
}

.coupon-code-toggle {
  font-size: 0.8em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.25em;
}
</style>